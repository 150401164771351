<template>
  <div class="home">
    <Header></Header>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Header from "@/components/header.vue";
export default {
  components: {
    Footer,
    Header,
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
}
@media (max-width: 750px) {
  .home {
    width: 100%;
  }
}
</style>